import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useMemo, useState } from 'react';
import { caloTheme } from '../../../assets/themes/calo';
import EmptyMessage from '../../../components/EmptyMessage';
import { FoodType, KDSExtraMeals } from '../../../libs';
import MealsTable from '../MealsTable';

interface MealsProps {
	foods: KDSExtraMeals[];
	isEditing: boolean;
	setIsEditing: (value: boolean) => void;
}

const Meals = ({ foods, isEditing, setIsEditing }: MealsProps) => {
	const normalFoods = foods.filter((food) => !food.isCustom);

	const keyedFoods = useMemo(
		() => ({
			[FoodType.breakfast]: normalFoods.filter((food) => food.type.includes(FoodType.breakfast)),
			[`${FoodType.lunch} & ${FoodType.dinner}`]: normalFoods.filter(
				(food) => food.type.includes(FoodType.lunch) || food.type.includes(FoodType.dinner)
			),
			[FoodType.snack]: normalFoods.filter((food) => food.type.includes(FoodType.snack)),
			[FoodType.coffee]: normalFoods.filter((food) => food.type.includes(FoodType.coffee)),
			[FoodType.juice]: normalFoods.filter((food) => food.type.includes(FoodType.juice)),
			[FoodType.caloKids]: normalFoods.filter((food) => food.type.includes(FoodType.caloKids))
		}),
		[normalFoods]
	);

	const [updatedFoods, setUpdatedFoods] = useState<KDSExtraMeals[]>([]);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.white,
			color: theme.palette.common.black,
			border: 'none',
			fontFamily: caloTheme.typography.fontFamily,
			fontSize: 23,
			fontWeight: 400,
			lineHeight: '28px',
			width: '50%'
		}
	}));

	return (
		<Stack direction="column" spacing={0} sx={{ mx: 2, px: 0, width: '90%' }}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<StyledTableCell>Meals</StyledTableCell>
						<StyledTableCell>
							<Stack direction="row" justifyContent="end" alignItems="center">
								Extra on Hand
							</Stack>
						</StyledTableCell>
					</TableRow>
				</TableHead>
			</Table>
			{foods.length > 0 ? (
				Object.entries(keyedFoods).map(([key, value], index) => (
					<MealsTable
						key={key}
						foods={value}
						tableName={key}
						isEditing={isEditing}
						isFirstTable={index === 0}
						setIsEditing={setIsEditing}
						updatedFoods={updatedFoods}
						setUpdatedFoods={setUpdatedFoods}
					/>
				))
			) : (
				<EmptyMessage label={'No Meals'} style={{ display: 'flex', justifyContent: 'center' }} />
			)}
		</Stack>
	);
};

export default Meals;

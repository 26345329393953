import { upperFirst } from 'lodash-es';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import { Checkbox, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { caloTheme } from '../../../assets/themes/calo';
import { DeliveryTicketWithIndex } from '../../../libs';

interface InvoiceCardProps {
	deliveryTicket: DeliveryTicketWithIndex;
	isSelected: boolean;
	isLastItem: boolean;
	setIsSelected: (id: string, isSelected: boolean) => void;
}

const InvoiceCard = ({ deliveryTicket, isSelected, isLastItem, setIsSelected }: InvoiceCardProps) => {
	const theme = useTheme();
	const { data } = deliveryTicket;

	return (
		<Card
			onClick={() => setIsSelected(deliveryTicket.data?.userId ?? '', !isSelected)}
			sx={{
				width: '100%',
				boxShadow: 0,
				maxHeight: '64px',
				borderBottom: isLastItem ? '0px' : '2px solid' + theme.palette.neutral100,
				borderRadius: '0px'
			}}
		>
			<CardContent>
				<Stack direction={'row'} justifyContent="space-between" alignItems="start" sx={{ textAlign: 'start' }}>
					<Checkbox checked={isSelected} onChange={() => setIsSelected(deliveryTicket.data?.userId ?? '', !isSelected)} sx={{ ml: -1, mr: 2, mt: -1 }} />
					<Typography
						component="div"
						sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: 19, fontWeight: 400, lineHeight: '19px', width: '25%' }}
					>
						Logistics change #{deliveryTicket.index}
					</Typography>
					<Typography component="div" sx={{ width: '20%', ml: 1, fontSize: 16, fontWeight: 400, lineHeight: '19px' }}>
						{upperFirst(data?.userName ?? '')}
					</Typography>
					<Typography component="div" sx={{ width: '15%', fontSize: 16, fontWeight: 400, lineHeight: '19px' }}>
						{data?.phoneNumber}
					</Typography>
					<Typography component="div" sx={{ width: '13%', fontSize: 16, fontWeight: 400, lineHeight: '19px' }}>
						#{data?.deliveryShortId || 'xxx-xxx'}
					</Typography>
					<Typography component="div" sx={{ width: '15%', fontSize: 16, fontWeight: 400, lineHeight: '19px' }}>
						{data?.addressTicketData?.newDriver
							? data?.addressTicketData?.newDriver?.name
							: data?.addressTicketData?.oldDriver?.name || 'xxx-xxx'}
					</Typography>
					<Typography component="div" sx={{ width: '12%', fontSize: 16, fontWeight: 400, lineHeight: '19px' }}>
						{upperFirst(data?.addressTicketData?.newDeliveryTime ? data?.addressTicketData!.newDeliveryTime : data?.deliveryTime)}
					</Typography>
					<Typography
						component="div"
						sx={{
							width: '5%',
							fontSize: 16,
							fontWeight: 600,
							lineHeight: '19px',
							flexDirection: 'row',
							display: 'flex',
							justifyContent: 'space-between'
						}}
					>
						{deliveryTicket.downloadedAt ? (
							<CheckCircleRoundedIcon sx={{ color: caloTheme.palette.success.main, fontSize: 30, marginRight: 2 }} />
						) : (
							<></>
						)}
						{data?.autoApprove ? (
							<PersonOutlineRoundedIcon sx={{ color: caloTheme.palette.primary.main, fontSize: 30, marginRight: 2 }} />
						) : (
							<SupportAgentRoundedIcon sx={{ color: caloTheme.palette.primary.main, fontSize: 30, marginRight: 2 }} />
						)}
					</Typography>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default InvoiceCard;

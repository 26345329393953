import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useCallback, useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Stack } from '@mui/material';
import { toast } from 'react-toastify';
import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { KDSExtraMeals } from '../../../libs';
import { UPDATE_EXTRA_MEALS_MUTATION } from '../../../libs/graphQL';
import MealsTableRow from '../MealsTableRow';

interface MealsTableProps {
	foods: KDSExtraMeals[];
	tableName: string;
	isEditing: boolean;
	isFirstTable: boolean;
	setIsEditing: (value: boolean) => void;
	updatedFoods: KDSExtraMeals[];
	setUpdatedFoods: React.Dispatch<React.SetStateAction<KDSExtraMeals[]>>;
}

const MealsTable = ({
	foods,
	tableName,
	isEditing,
	isFirstTable,
	setIsEditing,
	updatedFoods,
	setUpdatedFoods
}: MealsTableProps) => {
	const [loading, setLoading] = useState(false);
	const appContext = useContext(AppContext);

	const [updateExtraMeals] = useMutation(UPDATE_EXTRA_MEALS_MUTATION);

	const handleDone = useCallback(async () => {
		if (updatedFoods.length > 0) {
			setLoading(true);
			try {
				await updateExtraMeals({
					variables: {
						kitchen: foods[0]?.kitchen,
						data: updatedFoods.map(({ foodId, size, extraMeals }) => ({
							id: foodId,
							extraMeals,
							size
						}))
					}
				});
				toast.success('Updates saved successfully!');
				setUpdatedFoods([]);
			} catch (error: any) {
				toast.error(error.message);
			}
			setLoading(false);
		}
		setIsEditing(false);
	}, [updatedFoods, foods, updateExtraMeals, setIsEditing, setUpdatedFoods]);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral50,
			color: theme.palette.common.black,
			border: '1px solid ' + theme.palette.neutral50,
			fontSize: 16,
			fontWeight: 600,
			lineHeight: '19px',
			textTransform: 'capitalize',
			padding: '8px'
		}
	}));

	return (
		<Table stickyHeader sx={{ display: foods.length > 0 ? 'table' : 'none', zIndex: 0 }}>
			<TableHead>
				<TableRow>
					<StyledTableCell colSpan={2} sx={{ fontSize: '16px', fontWeight: 600, borderRadius: '4px' }}>
						{tableName}
					</StyledTableCell>
					<StyledTableCell>
						<Stack direction="row" justifyContent="end" alignItems="center">
							<Button
								disabled={foods.length === 0 || appContext.isOffline || loading}
								onClick={isEditing ? handleDone : () => setIsEditing(true)}
								variant="outlined"
								sx={{
									textTransform: 'capitalize',
									fontSize: '16px',
									borderWidth: 2,
									width: '80px',
									borderRadius: '8px',
									borderColor: caloTheme.palette.primary500,
									':hover': { borderWidth: 2, borderColor: caloTheme.palette.primary400 },
									visibility: isFirstTable ? 'visible' : 'hidden'
								}}
							>
								{isEditing ? 'Done' : 'Edit'}
							</Button>
						</Stack>
					</StyledTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{foods.map((food, index) => (
					<MealsTableRow
						isLastRow={foods.length === index + 1}
						key={`${food.foodId}-${food.size}`}
						food={food}
						isEditing={isEditing}
						setUpdatedFoods={setUpdatedFoods}
					/>
				))}
			</TableBody>
		</Table>
	);
};

export default MealsTable;

import { useLazyQuery } from '@apollo/client';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import { Button, Menu, MenuItem, Stack, useTheme } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { ExportBaggingSessionQuery, Kitchen, PreDispatchCheckFilters } from '../../../libs';
import { EXPORT_BAGGING_SESSION_QUERY } from '../../../libs/graphQL';
import { createAutoDownloadLinkFromFile, printFileFromBase64 } from '../../../libs/utils';

export default function ExportButton({
	date,
	kitchen,
	filters
}: {
	date: string;
	kitchen: Kitchen;
	filters: PreDispatchCheckFilters;
}) {
	const [exportButtonAnchorEl, setExportButtonAnchorEl] = useState<HTMLElement | null>(null);
	const open = Boolean(exportButtonAnchorEl);
	const theme = useTheme();

	const [getDocument, { loading }] = useLazyQuery<ExportBaggingSessionQuery>(EXPORT_BAGGING_SESSION_QUERY, {
		variables: {
			kitchen,
			startDate: date,
			endDate: date,
			shift: filters.shift,
			city: filters.cities,
			mealType: filters.mealTypes,
			onlySandwich: filters.onlySandwiches,
			actions: []
		},
		fetchPolicy: 'network-only'
	});

	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setExportButtonAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setExportButtonAnchorEl(null);
	};

	const printPDF = async () => {
		const { data } = await getDocument({ variables: { fileType: 'PDF' } });

		if (data?.exportBaggingSession && data?.exportBaggingSession.data) {
			printFileFromBase64(data.exportBaggingSession.data, 'application/pdf');
		}
	};

	const downloadCSV = async () => {
		const { data } = await getDocument({ variables: { fileType: 'CSV' } });

		if (data?.exportBaggingSession && data?.exportBaggingSession.data) {
			createAutoDownloadLinkFromFile(
				data.exportBaggingSession.data,
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'xlsx',
				`Calo Dispatch Record ${kitchen}-${filters.shift}-${date}`
			);
		}
	};

	return (
		<>
			<Button
				variant="outlined"
				disabled={loading}
				onClick={handleClick}
				endIcon={<ArrowDropDownIcon />}
				sx={{
					py: 1,
					borderColor: 'black',
					color: 'black',
					'&:hover': {
						backgroundColor: 'transparent',
						borderColor: theme.palette.neutral700
					}
				}}
			>
				Export
			</Button>
			<Menu
				anchorEl={exportButtonAnchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					horizontal: 'right',
					vertical: 'top'
				}}
				slotProps={{
					paper: {
						style: {
							width: '220px'
						}
					}
				}}
			>
				<MenuItem
					onClick={async () => {
						handleClose();
						await printPDF();
					}}
				>
					<Stack direction={'row'} gap={1.5}>
						<PrintIcon /> Print
					</Stack>
				</MenuItem>
				<MenuItem
					onClick={async () => {
						handleClose();
						await downloadCSV();
					}}
				>
					<Stack direction={'row'} gap={1.5}>
						<DownloadIcon /> Download
					</Stack>
				</MenuItem>
			</Menu>
		</>
	);
}

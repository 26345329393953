import { orderBy, uniq } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';


import { useQuery } from '@apollo/client';
import { DeliveryTicketFilters, GetDeliveryTicketsReq } from '@calo/dashboard-types';
import { Kitchen as KitchenEnum } from '@calo/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Button, Checkbox, FormControlLabel, MenuItem, Select, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../App';
import { caloTheme } from '../../assets/themes/calo';
import { DeliveryTicketStatus, DeliveryTicketType, DeliveryTicketWithIndex, Kitchen } from '../../libs';
import { LIST_DELIVERY_TICKETS_QUERY, SUBSCRIBE_TO_DELIVERY_TICKET_CHANGES_SUBSCRIPTION } from '../../libs/graphQL';
import ColumnAttr from './ColumnAttr';
import Export from './Export';
import SwapTab from './SwapTab';

interface LogisticsChangesProps {
	kitchen: Kitchen;
	date: string;
	notificationReq: number;
	setNotificationReq: (cxRequest: number, customerRequest: number) => void;
}

const LogisticsChanges = ({ kitchen, date, setNotificationReq, notificationReq }: LogisticsChangesProps) => {
	const theme = useTheme();
	const appContext = useContext(AppContext);
	const [tab, setTab] = useState<0 | 1>(0);
	const [addressDeliveryTickets, setAddressDeliveryTickets] = useState<DeliveryTicketWithIndex[]>([]);
	const [cancelDeliveryTickets, setCancelDeliveryTickets] = useState<DeliveryTicketWithIndex[]>([]);
	const [allDeliveryTickets, setAllDeliveryTickets] = useState<DeliveryTicketWithIndex[]>([]);
	const [filtersCheck, setFiltersCheck] = useState({ CXRequest: true, customerRequest: true });

	const [shiftFilter, setShiftFilter] = useState('all');
	const [typeFilter, setTypeFilter] = useState('all');
	const [availableZoneFilters, setAvailableZoneFilters] = useState<string[]>([]);
	const [zoneFilter, setZoneFilter] = useState('all');
	const [shiftMenuAnchorEl, setShiftMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [typeMenuAnchorEl, setTypeMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [zoneMenuAnchorEl, setZoneMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [requestTypefiltersMenuAnchorEl, setRequestTypefiltersMenuAnchorEl] = useState<null | HTMLElement>(null);

	const isShiftMenuOpened = Boolean(shiftMenuAnchorEl);
	const isTypeMenuOpened = Boolean(typeMenuAnchorEl);
	const isZoneMenuOpened = Boolean(zoneMenuAnchorEl);
	const isRequestTypeFilterMenuOpened = Boolean(requestTypefiltersMenuAnchorEl);

	const handleChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
		setTab(newValue);
	};
	const filters: Partial<DeliveryTicketFilters> = {
		kitchen: kitchen as unknown as KitchenEnum,
		date: { lte: date, gte: date }
	};

	useEffect(() => {
		if (allDeliveryTickets.length > 0) {
			const filteredTickets = allDeliveryTickets.filter((d) => d.zone && d.kitchen === kitchen).map((d) => d.zone || '');
			setAvailableZoneFilters(uniq(filteredTickets));
		}
	}, [allDeliveryTickets, kitchen]);

	const {
		loading: addressLoading,
		refetch: addressRefetch,
		subscribeToMore
	} = useQuery(LIST_DELIVERY_TICKETS_QUERY, {
		variables: {
			query: {
				filters: { ...filters, type: DeliveryTicketType.address }
			} as unknown as Partial<GetDeliveryTicketsReq>
		},
		onCompleted: (data) => {
			setAddressDeliveryTickets(data?.listDeliveryTickets.data);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite',
		fetchPolicy: 'network-only'
	});

	const { loading: cancelLoading, refetch: cancelRefetch } = useQuery(LIST_DELIVERY_TICKETS_QUERY, {
		variables: {
			query: {
				filters: { ...filters, type: DeliveryTicketType.cancel }
			} as unknown as Partial<GetDeliveryTicketsReq>
		},
		onCompleted: (data) => {
			setCancelDeliveryTickets(data?.listDeliveryTickets.data);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite',
		fetchPolicy: 'network-only'
	});

	const getMoreData = () => {
		subscribeToMore({
			document: SUBSCRIBE_TO_DELIVERY_TICKET_CHANGES_SUBSCRIPTION,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const subscriberData = subscriptionData.data.subscribeToDeliveryTicketChanges;
				const prevTickets = Array.isArray(prev?.listDeliveryTickets?.data) ? prev.listDeliveryTickets.data : [];
				const isDeliveryTicketExist = prevTickets.find(
					(deliveryTicket: DeliveryTicketWithIndex) => deliveryTicket.id === subscriberData.id
				);
				const isDeliveryTicketValid =
					(subscriberData.data.type === DeliveryTicketType.cancel || subscriberData.data.type === DeliveryTicketType.address) &&
					subscriberData.kitchen === kitchen &&
					subscriberData.date === date;
				if (!isDeliveryTicketExist && isDeliveryTicketValid) {
					return {
						...prev,
						listDeliveryTickets: {
							...prev.listDeliveryTickets,
							data: [subscriberData, ...prevTickets]
						}
					};
				}

				return prev;
			}
		});
	};

	useEffect(() => {
		addressRefetch();
		cancelRefetch();
		getMoreData();
		setZoneFilter('all');
		setAvailableZoneFilters([]);
	}, [kitchen, date]);

	useEffect(() => {
		const newDeliveryTickets = orderBy([...addressDeliveryTickets, ...cancelDeliveryTickets], ['createdAt'], ['asc'])
			.map((ticket, index) => ({ ...ticket, index: index + 1 }))
			.filter(filterTicket);

		setAllDeliveryTickets(newDeliveryTickets);
		setNotificationReq(
			[...addressDeliveryTickets, ...cancelDeliveryTickets].filter((ticket) => ticket.status === DeliveryTicketStatus.pending)
				.length,
			[...addressDeliveryTickets, ...cancelDeliveryTickets].filter((ticket) => ticket.data?.autoApprove && !ticket.downloadedAt)
				.length
		);
	}, [addressDeliveryTickets, cancelDeliveryTickets, date, kitchen, filtersCheck]);

	const filterTicket = (ticket: DeliveryTicketWithIndex) =>
		filtersCheck.CXRequest ? (filtersCheck.customerRequest ? ticket : !ticket.data?.autoApprove) : ticket.data?.autoApprove;

	const filterDeliveryTickets = (deliveryTickets: DeliveryTicketWithIndex[]) =>
		deliveryTickets.filter((delivery) => {
			const isShiftMatching = shiftFilter === 'all' ? true : delivery.data?.deliveryTime?.toLocaleLowerCase() === shiftFilter;
			const isTypeMatching = typeFilter === 'all' ? true : delivery.data?.type === typeFilter;
			const isZoneMatching = zoneFilter === 'all' ? true : delivery.zone === zoneFilter;

			return isShiftMatching && isTypeMatching && isZoneMatching;
		});

	return (
		<Box sx={{ width: '100%', mt: 2 }}>
			<Stack
				direction={'column'}
				justifyContent="space-between"
				alignItems="end"
				sx={{ width: appContext.isSidebarOpened ? '95%' : '96%', ml: 3 }}
			>
				<Stack direction={'row'} justifyContent="space-between" sx={{ width: '100%', mt: '4px', mb: 4 }}>
					<Stack direction={'row'} justifyContent="start" sx={{ width: '100%', mt: '4px' }}>
						{tab === 1 && (
							<Button
								onClick={(event) => {
									!requestTypefiltersMenuAnchorEl && setRequestTypefiltersMenuAnchorEl(event.currentTarget);
								}}
								variant="outlined"
								endIcon={<ExpandMoreIcon />}
								sx={{
									textTransform: 'capitalize',
									fontSize: '16px',
									mr: 2,
									color: caloTheme.palette.neutral900,
									maxHeight: '40px',
									borderColor: caloTheme.palette.neutral900,
									borderWidth: '1.5px',
									width: '179px',
									'&:hover': {
										backgroundColor: 'transparent',
										color: caloTheme.palette.neutral900,
										borderColor: caloTheme.palette.neutral400,
									}
								}}
							>
								{filtersCheck.CXRequest ? (filtersCheck.customerRequest ? 'All REQUESTS' : 'CX REQUESTS') : 'CUSTOMER REQUESTS'}
								<Select
									id="demo-simple-select"
									open={isRequestTypeFilterMenuOpened}
									onClose={() => setRequestTypefiltersMenuAnchorEl(null)}
									label="session"
									sx={{ textTransform: 'capitalize', fontSize: '16px', visibility: 'hidden', width: 0, height: 0, mt: 7, mr: 2 }}
								>
									<MenuItem sx={{ fontWeight: 600 }}>
										<FormControlLabel
											control={
												<Checkbox
													checked={filtersCheck.customerRequest}
													disabled={filtersCheck.customerRequest && !filtersCheck.CXRequest}
													onChange={() => setFiltersCheck({ ...filtersCheck, customerRequest: !filtersCheck.customerRequest })}
												/>
											}
											label="Customer Requests"
										/>
									</MenuItem>
									<MenuItem sx={{ fontWeight: 600 }}>
										<FormControlLabel
											control={
												<Checkbox
													checked={filtersCheck.CXRequest}
													disabled={!filtersCheck.customerRequest && filtersCheck.CXRequest}
													onChange={() => setFiltersCheck({ ...filtersCheck, CXRequest: !filtersCheck.CXRequest })}
												/>
											}
											label="CX Requests"
										/>
									</MenuItem>
								</Select>
							</Button>
						)}
					</Stack>
					<Stack direction={'row'} justifyContent="end" sx={{ width: '100%', mt: '4px' }}>
						<Button
							onClick={(event) => {
								!zoneMenuAnchorEl && setZoneMenuAnchorEl(event.currentTarget);
							}}
							variant="outlined"
							disabled={availableZoneFilters.length === 0}
							startIcon={<LocationOnRoundedIcon />}
							endIcon={<ExpandMoreIcon />}
							sx={{
								textTransform: 'capitalize',
								fontSize: '16px',
								mr: 2,
								maxHeight: '40px',
								color: caloTheme.palette.neutral900,
								borderColor: caloTheme.palette.neutral900,
								borderWidth: '1.5px',
								'&:hover': {
									backgroundColor: 'transparent',
									color: caloTheme.palette.neutral900,
									borderColor: caloTheme.palette.neutral400,
								},
								width: 124
							}}
						>
							{zoneFilter}
							<Select
								id="demo-simple-select"
								value={zoneFilter}
								open={isZoneMenuOpened}
								onClose={() => setZoneMenuAnchorEl(null)}
								label="session"
								sx={{ visibility: 'hidden', width: 0, height: 0, mt: 7 }}
							>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'all'}
									onClick={() => {
										setZoneFilter('all');
										setZoneMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>All</Box>
								</MenuItem>
								{availableZoneFilters.length > 0 &&
									availableZoneFilters.map((z) => (
										<MenuItem
											key={z}
											sx={{ fontWeight: 600 }}
											value={z}
											onClick={() => {
												setZoneFilter(z);
												setZoneMenuAnchorEl(null);
											}}
										>
											<Box sx={{ px: '15px' }}>{z}</Box>
										</MenuItem>
									))}
							</Select>
						</Button>
						<Button
							onClick={(event) => {
								!typeMenuAnchorEl && setTypeMenuAnchorEl(event.currentTarget);
							}}
							variant="outlined"
							endIcon={<ExpandMoreIcon />}
							startIcon={<LocalShippingRoundedIcon />}
							sx={{
								'&:hover': {
									backgroundColor: 'transparent',
									color: caloTheme.palette.neutral900,
									borderColor: caloTheme.palette.neutral400,
								},
								textTransform: 'capitalize', fontSize: '16px', mr: 2, maxHeight: '40px', color: caloTheme.palette.neutral900, borderColor: caloTheme.palette.neutral900, borderWidth: '1.5px', width: 124
							}}
						>
							{typeFilter}
							<Select
								id="demo-simple-select"
								value={typeFilter}
								open={isTypeMenuOpened}
								onClose={() => setTypeMenuAnchorEl(null)}
								label="session"
								sx={{ visibility: 'hidden', width: 0, height: 0, mt: 7 }}
							>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'all'}
									onClick={() => {
										setTypeFilter('all');
										setTypeMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>All</Box>
								</MenuItem>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'cancel'}
									onClick={() => {
										setTypeFilter('cancel');
										setTypeMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>Cancel Delivery</Box>
								</MenuItem>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'address'}
									onClick={() => {
										setTypeFilter('address');
										setShiftMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>Address Change</Box>
								</MenuItem>
							</Select>
						</Button>

						<Button
							onClick={(event) => {
								!shiftMenuAnchorEl && setShiftMenuAnchorEl(event.currentTarget);
							}}
							variant="outlined"
							startIcon={<ScheduleIcon />}
							endIcon={<ExpandMoreIcon />}
							sx={{
								'&:hover': {
									backgroundColor: 'transparent',
									color: caloTheme.palette.neutral900,
									borderColor: caloTheme.palette.neutral400,
								},
								textTransform: 'capitalize', fontSize: '16px', maxHeight: '40px', color: caloTheme.palette.neutral900, borderColor: caloTheme.palette.neutral900, borderWidth: '1.5px', width: 124
							}}
						>
							{shiftFilter}
							<Select
								id="demo-simple-select"
								value={shiftFilter}
								open={isShiftMenuOpened}
								onClose={() => setShiftMenuAnchorEl(null)}
								label="session"
								sx={{ visibility: 'hidden', width: 0, height: 0, mt: 7 }}
							>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'all'}
									onClick={() => {
										setShiftFilter('all');
										setShiftMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>All</Box>
								</MenuItem>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'morning'}
									onClick={() => {
										setShiftFilter('morning');
										setShiftMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>Morning Shift</Box>
								</MenuItem>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'evening'}
									onClick={() => {
										setShiftFilter('evening');
										setShiftMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>Evening Shift</Box>
								</MenuItem>
							</Select>
						</Button>
					</Stack>
					{tab === 1 && (
						<Export
							deliveryTickets={[...orderBy(allDeliveryTickets, ['createdAt'], ['asc'])].filter(
								(deliveryTicket) =>
									deliveryTicket.status === DeliveryTicketStatus.approved &&
									deliveryTicket.data?.type !== DeliveryTicketType.cancel
							)}
						/>
					)}
				</Stack>
				<Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
					<Tabs variant="fullWidth" value={tab} onChange={handleChange} aria-label="basic tabs example" centered>
						<Tab
							sx={{ fontSize: '19px', textTransform: 'capitalize' }}
							label={`NEW REQUESTS (${[...addressDeliveryTickets, ...cancelDeliveryTickets].filter((d) => d.status === DeliveryTicketStatus.pending)
								.length
								})`}
							id={'New Requests'}
							aria-controls={'New Requests'}
						/>
						<Tab
							sx={{ fontSize: '19px', textTransform: 'capitalize' }}
							label={`DONE REQUESTS (${notificationReq >= 0 ? notificationReq + ' New' : ''})`}
							id={'Done Requests'}
							aria-controls={'Done Requests'}
						/>
					</Tabs>
				</Box>
				<Box
					display={'flex'}
					flexDirection={'row'}
					justifyContent="space-between"
					alignItems="center"
					sx={{
						width: '100%', minHeight: '56px', backgroundColor: theme.palette.neutral50, mt: 2, pl: 2, borderBottom: `1.5px solid ${theme.palette.divider}`
					}}
				>
					<ColumnAttr sx={{ width: '13%', justifyContent: 'start' }}>Type</ColumnAttr>
					<ColumnAttr sx={{ width: '13%', justifyContent: 'start' }}>Name</ColumnAttr>
					<ColumnAttr sx={{ width: '10%', justifyContent: 'start' }}>Phone Number</ColumnAttr>
					<ColumnAttr sx={{ width: '10%', justifyContent: 'start' }}>Old Invoice</ColumnAttr>
					<ColumnAttr sx={{ width: '10.5%', justifyContent: 'start' }}>New Invoice</ColumnAttr>
					<ColumnAttr sx={{ width: '10%', justifyContent: 'start' }}>Zone</ColumnAttr>
					<ColumnAttr sx={{ width: '10%', justifyContent: 'start' }}>Status</ColumnAttr>
					<ColumnAttr sx={{ width: '15%', justifyContent: 'start' }}>Actions</ColumnAttr>
				</Box>
				<SwapTab
					value={tab}
					index={0}
					loading={addressLoading || cancelLoading}
					deliveryTickets={orderBy(filterDeliveryTickets(allDeliveryTickets), ['createdAt'], ['desc'])?.filter(
						(d) => d.status === DeliveryTicketStatus.pending
					)}
				/>
				<SwapTab
					value={tab}
					index={1}
					loading={addressLoading || cancelLoading}
					deliveryTickets={orderBy(filterDeliveryTickets(allDeliveryTickets), ['createdAt'], ['asc'])?.filter(
						(d) => d.status !== DeliveryTicketStatus.pending
					)}
				/>
			</Stack>

			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={(addressLoading || cancelLoading) && !appContext.isOffline}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
};
export default LogisticsChanges;

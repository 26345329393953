import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';

import { useContext, useState } from 'react';
import { AppContext } from '../../../App';
import { KDSExtraMeals } from '../../../libs';

interface SizeRowProps {
	food: KDSExtraMeals;
	isEditing: boolean;
	isLastRow: boolean;
	setUpdatedFoods: React.Dispatch<React.SetStateAction<KDSExtraMeals[]>>;
}

const SizeRow = ({ food, isEditing, setUpdatedFoods, isLastRow }: SizeRowProps) => {
	const [extraMeals, setExtraMeals] = useState(food.extraMeals || 0);
	const theme = useTheme();
	const appContext = useContext(AppContext);

	const updateLocalState = (newExtraMeals: number) => {
		setExtraMeals(newExtraMeals);
		setUpdatedFoods((prev) => {
			const filtered = prev.filter((item) => !(item.foodId === food.foodId && item.size === food.size));
			return [...filtered, { ...food, extraMeals: newExtraMeals }];
		});
	};

	return (
		<TableRow>
			<TableCell
				sx={{
					fontSize: '17px',
					fontWeight: 600,
					borderColor: isLastRow ? 'transparent' : theme.palette.neutral100
				}}
			>
				{food.name.en}
			</TableCell>
			<TableCell
				sx={{
					fontSize: '17px',
					fontWeight: 600,
					borderColor: isLastRow ? 'transparent' : theme.palette.neutral100
				}}
			>
				{food.size}
			</TableCell>

			<TableCell
				sx={{
					fontSize: '17px',
					fontWeight: 400,
					pr: 0,
					width: '40%',
					borderColor: isLastRow ? 'transparent' : theme.palette.neutral100
				}}
			>
				<Stack direction="row" alignItems="center" justifyContent="center">
					<IconButton
						disabled={extraMeals === 0 || appContext.isOffline}
						onClick={() => extraMeals > 0 && updateLocalState(extraMeals - 1)}
						sx={{
							visibility: isEditing ? 'visible' : 'hidden',
							color: theme.palette.primary500,
							backgroundColor: theme.palette.primary50,
							p: 1,
							m: 0,
							mr: 2,
							':hover': { color: theme.palette.primary50, backgroundColor: theme.palette.primary500 }
						}}
					>
						<RemoveIcon />
					</IconButton>
					<Box sx={{ p: 2 }}>{extraMeals}</Box>
					<IconButton
						disabled={appContext.isOffline}
						onClick={() => updateLocalState(extraMeals + 1)}
						sx={{
							visibility: isEditing ? 'visible' : 'hidden',
							color: theme.palette.primary500,
							backgroundColor: theme.palette.primary50,
							p: 1,
							m: 0,
							ml: 2,
							':hover': { color: theme.palette.primary50, backgroundColor: theme.palette.primary500 }
						}}
					>
						<AddIcon />
					</IconButton>
				</Stack>
			</TableCell>
		</TableRow>
	);
};

export default SizeRow;

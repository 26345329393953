import { KDSExtraMeals } from '../../../libs';
import SizeRow from './SizeRow';

interface MealsTableRowProps {
	food: KDSExtraMeals;
	isLastRow: boolean;
	isEditing: boolean;
	setUpdatedFoods: React.Dispatch<React.SetStateAction<KDSExtraMeals[]>>;
}

const MealsTableRow = ({ food, isLastRow, isEditing, setUpdatedFoods }: MealsTableRowProps) => (
	<SizeRow isLastRow={isLastRow} food={food} isEditing={isEditing} setUpdatedFoods={setUpdatedFoods} />
);

export default MealsTableRow;

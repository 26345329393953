import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { BaggingDispatch, PreDispatchCheckFilters } from '../../../libs';
import { sumDispatchTotals } from '../../../libs/utils/helperFunctions';

interface SectionTableRowProps {
	dispatch: BaggingDispatch;
	filters: PreDispatchCheckFilters;
}

const SectionTableRow = ({ dispatch, filters }: SectionTableRowProps) => {
	const { cities } = filters;

	const extra = dispatch.extra ?? 0;
	const leftOver = dispatch.leftovers ?? 0;
	const { totalCount, modifiedCount } = sumDispatchTotals(dispatch, cities);

	return (
		<TableRow hover>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', fontWeight: 600 }}>{dispatch.foodName?.en}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.foodSize ? dispatch.foodSize : '[N/A]'}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.packages && dispatch.packages?.length > 0 ? dispatch.packages.map((p) => p.name).join(', ') : '-'}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{totalCount}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{totalCount + extra - modifiedCount}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{modifiedCount}</TableCell>
			<TableCell sx={{ fontSize: '14px', textAlign: 'center' }}>{leftOver}</TableCell>
		</TableRow>
	);
};

export default SectionTableRow;

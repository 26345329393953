import { TableCell, TableRow } from "@mui/material";
import { round } from "lodash-es";
import { BaggingDispatch } from "../../../../libs";
import { getCurrencyFromKitchen, sumDispatchTotals } from "../../../../libs/utils";
import { MealViewFilters } from "../MealsView";

export interface SectionTableRowProps {
  dispatchedMeal: BaggingDispatch;
  filters: MealViewFilters
}

export default function SectionTableRow({ dispatchedMeal, filters: { cities, kitchen } }: SectionTableRowProps) {
  const { totalCount, modifiedCount } = sumDispatchTotals(dispatchedMeal, cities)
  const currency = getCurrencyFromKitchen(kitchen);

  return (
    <TableRow>
      <TableCell sx={{ pl: 2, py: 2, fontSize: '14px' }}>{dispatchedMeal.foodName.en}</TableCell>
      <TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{dispatchedMeal.foodSize}</TableCell>
      <TableCell sx={{ pl: 2, py: 2, fontSize: '14px' }}>
        {dispatchedMeal.packages && dispatchedMeal.packages?.length > 0 ? dispatchedMeal.packages.map((p) => p.name).join(', ') : '-'}
      </TableCell>
      <TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
        {totalCount}
      </TableCell>
      <TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
        {totalCount - modifiedCount}
      </TableCell>
      <TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
        {modifiedCount}
      </TableCell>
      <TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
        {dispatchedMeal.leftovers ?? 0}
      </TableCell>
      <TableCell sx={{ pl: 2, py: 2, fontSize: '14px' }}>
        {currency} {round((dispatchedMeal.packagingCost ?? 0) + (dispatchedMeal.purchasingCost ?? 0), 4)}
      </TableCell>
      <TableCell sx={{ pl: 2, py: 2, fontSize: '14px' }}>
        {currency} {round(dispatchedMeal.purchasingCost ?? 0, 4)}
      </TableCell>
      <TableCell sx={{ pl: 2, py: 2, fontSize: '14px' }}>
        {currency} {round(dispatchedMeal.packagingCost ?? 0, 4)}
      </TableCell>
    </TableRow>
  )
}

import { caloTheme } from "../../../assets/themes/calo";
import { DeliveryTicketStatus } from "../../../libs";


export const getStatusColor = (status: DeliveryTicketStatus) => {
  switch (status) {
    case DeliveryTicketStatus.approved:
      return caloTheme.palette.green[50];
    case DeliveryTicketStatus.rejected:
      return caloTheme.palette.secondaryRed50
    case DeliveryTicketStatus.pending:
      return caloTheme.palette.secondaryYellow100
    default:
      return caloTheme.palette.secondaryYellow50
  }
};